<template>
  <div class="onboarding-page__wrapper">
    <div class="interest-page">
      <h2>판매할 콘텐츠는 어떤 <br class="mo" />주제인가요?</h2>
      <p class="sub-text-s2 text-gray-second">
        크리에이터님이 빅크에서 판매하려는 콘텐츠의 주제를 최대 5개까지
        선택해주세요.
      </p>

      <div>
        <ul class="interest-list">
          <li
            v-for="interest in state.interests"
            :key="`interest-item-${interest.id}`"
          >
            <button-basic
              bg-color="#ffffff"
              shape="round"
              :color="
                state.selectedInterests.includes(interest.name)
                  ? '#8E1EFF'
                  : '#0D0D10'
              "
              :border="state.selectedInterests.includes(interest.name)"
              :border-color="
                state.selectedInterests.includes(interest.name)
                  ? `#8E1EFF`
                  : false
              "
              text-size="s2"
              padding="7px 16px"
              :text="interest.name"
              @action="actions.toggleInterest(interest)"
            >
            </button-basic>
          </li>
        </ul>

        <button-basic
          class="save-btn"
          text="다음"
          :disabled="state.disableSaveBtn"
          @action="actions.saveInterests()"
        >
          다음
        </button-basic>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ButtonBasic from "../../../../components/console/buttons/ButtonBasic";
import { computed, onBeforeMount, reactive } from "vue";
import ApiService from "@/api";
import swal from "@/helper/swal";

export default {
  name: "OnBoardingInterest",
  components: { ButtonBasic },
  setup() {
    const store = useStore();
    const router = useRouter();

    const state = reactive({
      interests: [],
      selectedInterests: [],
      disableSaveBtn: computed(() => {
        return state.selectedInterests.length === 0;
      }),
    });

    onBeforeMount(() => {
      ApiService.getOnBoardingInterest().then((res) => {
        if (res.data.success) {
          state.interests = res.data.meta.interestTags;
          state.selectedInterests = res.data.data.reduce((result, current) => {
            result.push(current.name);
            return result;
          }, []);
        }
      });
    });

    const actions = {
      saveInterests: () => {
        store
          .dispatch("onBoardings/putOnBoardingInterest", {
            tags: state.selectedInterests,
          })
          .then(() => {
            router.push({ name: "console.onboarding.purpose" });
          });
      },
      toggleInterest: (interest) => {
        let interestIndex = state.selectedInterests.findIndex((item) => {
          return item === interest.name;
        });

        if (interestIndex > -1) {
          state.selectedInterests.splice(interestIndex, 1);
        } else {
          if (state.selectedInterests.length < 5) {
            state.selectedInterests.push(interest.name);
          } else {
            swal.messageAlert("최대 5개까지만 선택할 수 있습니다.");
          }
        }
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
